import React from 'react'
import styled from 'styled-components'
import {graphql, useStaticQuery} from 'gatsby'
import FacebookSvg from '../../../images/svg/facebook.jsx'
import TwitterSvg from '../../../images/svg/twitter.jsx'
import LinkedinSvg from '../../../images/svg/linkedin.jsx'

const SocialButtonsWrapper = styled.div`
  padding: 0;
  margin-bottom: 20px;
  border-radius: 3px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: center;
  @media (min-width: 600px) {
    box-shadow: 0 2px 2px 2px #00000008;
    background: #fff;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    margin: 20px;
    grid-row-gap: 20px;
  }
`
const ShareLink = styled.a`
  padding: 20px;
  color: #bdbdca;
  display: block;
  text-align: center;
  transition: all 300ms ease-in-out;
  img {
    padding-right: 20px;
  }
  &:hover {
    color: #fff;
    cursor: pointer;
    &.twitter {
      background: #1da1f2;
    }
    &.facebook {
      background: #4267b2;
    }
    &.linkedin {
      background: #0077b5;
    }
  }
  &:first-child {
    border-radius: 3px 0 0 3px;
  }
  &:last-child {
    border-radius: 0 3px 3px 0;
  }
  @media (max-width: 600px) {
    border-radius: 3px;
    box-shadow: 0 2px 2px 2px #00000008;
    background: #fff;
  }
`

const SocialSharingButtons = ({title, url, excerpt}) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)
  const shareUrl = encodeURIComponent(
    data.site.siteMetadata.siteUrl + '/blog/' + url,
  )
  return (
    <SocialButtonsWrapper>
      <ShareLink
        href={`https://twitter.com/intent/tweet/?text=${title}&url=${shareUrl}%2F&via=kooibeds`}
        target="_blank"
        className="twitter"
      >
        <img src={TwitterSvg} alt="Share on Twitter" />
        Share on Twitter
      </ShareLink>
      <ShareLink
        href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
        target="_blank"
        className="facebook"
      >
        <img src={FacebookSvg} alt="Share on Facebook" />
        Share on Facebook
      </ShareLink>
      <ShareLink
        href={`
        https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`}
        target="_blank"
        className="linkedin"
      >
        <img src={LinkedinSvg} alt="Share on Linkedin" />
        Share on LinkedIn
      </ShareLink>
    </SocialButtonsWrapper>
  )
}

export default SocialSharingButtons
